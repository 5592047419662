import React, { useContext, useRef, useState } from "react";
import {
  ButtonReset,
  ButtonSubmit,
  Container,
  ContainerBackgroundBanner,
  ContainerBannerInfos,
  ContainerButtonForm,
  ContainerIndiceForm,
  ContainerInputs,
  DataContainerForm,
  Form,
  IndiceForm,
  SuportTitle,
  ModalOverlay,
  PerformanceContainer,
  StyledAlert,
  TitleBanner,
  TitleForm,
} from "./styled";
import InputText from "../../components/InputText";
import RadioForm from "../../components/RadioForm";
import SelectForm from "../../components/SelectForm";
import TextAreaForm from "../../components/TextAreaForm";
import YearSelectForm from "../../components/YearSelectForm";
import { useForm } from "react-hook-form";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { addAtor } from "../../api/apiProjeto/apiProjeto";
import AccordionForm from "../../components/AccordionForm";
import { getInfoByCep } from "../../api/apiViaCep";
import { getCoordenates } from "../../api/apiGeolocation";
import { LoadingContext } from "../../context/LoadingContext";

function SuportRegistration() {
  const optionsTipoOrganizacao = ["Associação", "Centro de Pesquisa", "Coletivo Informal", "Cooperativa", "Empresa Privada", "Empreendedor Pessoa Física", "Fundação", "Movimento Social", "Organização sem fins lucrativos", "Partido Político", "Poder Público", "Sindicato ou Entidade de Classe"];
  const optionsOrigemOrganizacao = ["Empresarial", "Governo Municipal", "Governo Estadual", "Governo Federal", "Religiosa", "Sociedade Civil", "Universidade ou IES"];
  const optionsAbrangencia = ["Local", "Regional", "Nacional", "Internacional"];
  const optionsAtuacao = ["Aceleração", "Advocacy", "Apoio Técnico", "Articulação", "Certificação", "Comunicação e Diálogo", "Controle e account.", "Financiamento", "Formação", "Incubadora", "Plataforma ou aplicativo", "Programa de empresas ou RSC", "Programa Governamental", "Programa OSC", "Programa Universitário", "Rede"];
  const optionsPublicoAlvo = ["Associações", "Coletivos informais", "Comunidades", "Cooperativas", "Empreendedor pessoa física", "Empresa com missão social", "Empresa privada", "Fundações", "Governo", "Movimentos Sociais", "Redes (Suporte)"];
  const optionsCausa = ["Agricultura urbana", "Assistência social", "Ativismo, mobilização política e voluntariado", "Conservação e preservação ambiental", "Consumo consciente", "Cultura e arte", "Desenvolvimento comunitário", "Desenvolvimento urbano", "Direitos da pessoa idosa", "Direitos das crianças e adolescentes", "Direitos dos animais", "Direitos dos imigrantes e refugiados", "Educação", "Esporte e recreação", "Gênero", "Gestão de águas e saneamento", "Gestão de resíduos e reciclagem", "Inclusão de pessoas com deficiência", "População em situação de rua", "Questões Raciais e Étnicas", "Saúde", "Segurança", "Segurança Alimentar e Nutricional", "Trabalho e renda", "Transporte e mobilidade", "Uso de tecnologia para mudança social"];
  const optionsRecursos = ["Financeiros", "Humanos", "Informacional", "Infraestrutura", "Formação"];

  const { register, handleSubmit, reset, setValue, watch } = useForm();
  const [show, setShow] = useState(false);
  const formRef = useRef(null);
  const [selectedCausas, setSelectedCausas] = useState([]);
  const [selectedTipoOrganizacao, setSelectedTipoOrganizacao] = useState([]);
  const [selectedOrigemOrganizacao, setSelectedOrigemOrganizacao] = useState([]);
  const [selectedRecursos, setSelectedRecursos] = useState([]);

  const { setIsLoading } = useContext(LoadingContext);

  const [selectedAtuacoes, setSelectedAtuacoes] = useState({
    principalAtuacao: "",
    secundariaAtuacao: "",
    terciariaAtuacao: "",
  });

  const [selectedPublico, setSelectedPublico] = useState({
    principalPublico: "",
    secundarioPublico: "",
    terciarioPublico: "",
  });

  function montarRequestAtuacao(selectedAtuacoes, data) {
    return Object.keys(selectedAtuacoes).map((selectId) => {
      const atuacao = selectId === "principalAtuacao" ? "ALTA" : (selectId === "secundariaAtuacao" ? "MEDIA" : "BAIXA");
      return {
        descricaoAtuacao: data[selectId],
        atuacao: atuacao
      };
    });
  }

  function montarRequestPublico(selectedPublico, data) {
    return Object.keys(selectedPublico).map((selectId) => {
      const frequencia = selectId === "principalPublico" ? "ALTA" : (selectId === "secundarioPublico" ? "MEDIA" : "BAIXA");
      return {
        descricaoFrequenciaPublico: data[selectId],
        frequencia: frequencia
      };
    });
  }

  function montarRequestCausa(selectedCausas, data) {
    return selectedCausas.map((causa) => {
      return {
        descricaoCausaAtor: causa
      };
    });
  }

  function montarRequestTipoOrganizacao(selectedTipoOrganizacao, data) {
    return selectedTipoOrganizacao.map((tipo) => {
      return {
        descricaoOrganizacaoAtor: tipo
      };
    });
  }

  function montarRequestOrigemOrganizacao(selectedOrigemOrganizacao, data) {
    return selectedOrigemOrganizacao.map((origem) => {
      return {
        descricaoOrigem: origem
      };
    });
  }

  function montarRequestRecursos(selectedRecursos, data) {
    return selectedRecursos.map((recurso) => {
      return {
        descricaoRecurso: recurso
      };
    });
  }

  const onSelectChange = (selectId, selectedValue, type) => {
    if (type === "atuacao") {
      setSelectedAtuacoes((prev) => ({
        ...prev,
        [selectId]: selectedValue,
      }));
    } else if (type === "publico") {
      setSelectedPublico((prev) => ({
        ...prev,
        [selectId]: selectedValue,
      }));
    }

  };

  const handleCheckboxChange = (checked, value, identifier) => {
    if (identifier === "causaEspecifica") {
      const newSelectedCausas = checked
        ? [...selectedCausas, value]
        : selectedCausas.filter((item) => item !== value);

      setSelectedCausas(newSelectedCausas);
    } else if (identifier === "tipoOrganizacao") {
      const newSelectedTipoOrganizacao = checked
        ? [...selectedTipoOrganizacao, value]
        : selectedTipoOrganizacao.filter((item) => item !== value);

      setSelectedTipoOrganizacao(newSelectedTipoOrganizacao);
    } else if (identifier === "origemOrganizacao") {
      const newSelectedOrigemOrganizacao = checked
        ? [...selectedOrigemOrganizacao, value]
        : selectedOrigemOrganizacao.filter((item) => item !== value);

      setSelectedOrigemOrganizacao(newSelectedOrigemOrganizacao);
    } else if (identifier === "descricaoRecurso") {
      const newSelectedRecurso = checked
        ? [...selectedRecursos, value]
        : selectedRecursos.filter((item) => item !== value);

      setSelectedRecursos(newSelectedRecurso);
    }
  };

  let cepInfo = null;

  const addPost = async (data) => {
    data.requestAtuacao = montarRequestAtuacao(selectedAtuacoes, data);
    data.requestFrequencia = montarRequestPublico(selectedPublico, data);
    data.requestCateg = montarRequestCausa(selectedCausas, data);
    data.requestOrg = montarRequestTipoOrganizacao(selectedTipoOrganizacao, data);
    data.requestOrigem = montarRequestOrigemOrganizacao(selectedOrigemOrganizacao, data);
    data.requestRecursos = montarRequestRecursos(selectedRecursos, data);
    data.ativo = "inativo";

    const cep = data.cep.replace(/\D/g, "");

    if (data.requestOrg.length <= 0 && data.requestOrigem <= 0) {
      window.scrollTo(0, 1200);
      alert("Preencha todos os campos corretamente!")
      return
    }

    setIsLoading(true)
    try {
      const response = await getCoordenates(cepInfo.data.logradouro, cepInfo.data.bairro, cepInfo.data.localidade);

      if (response && cep.length === 8) {
        const { lat, lng } = response;
        data.latitude = lat;
        data.longitude = lng;

        console.log("Dados: ", data);
        addAtor(data);

        setShow(true);
      } else {
        alert('Erro ao cadastrar informações, confira antes de tentar novamente!');
        throw new Error("A resposta da API não contém informações de geometria.");
      }
    } catch (error) {
      alert("Erro: CEP inválido.");
      setShow(false);
    }
    setIsLoading(false)
  };

  const onCepFieldBlurChange = (event) => {
    const cepFieldValue = event.target.value
    const maxCepFieldLength = 9

    const cepFieldValueLength = cepFieldValue?.length;
    if (cepFieldValueLength !== maxCepFieldLength)
      return;

    setIsLoading(true);

    getInfoByCep(cepFieldValue)
      .then((viaCepInfo) => {
        cepInfo = viaCepInfo;

        setValue('logradouro', cepInfo.data.logradouro);
        setValue('bairro', cepInfo.data.bairro);
        setValue('localidade', cepInfo.data.localidade);
        setValue('uf', cepInfo.data.uf);
        setValue('pais', 'Brasil');
        setValue('complemento', cepInfo.data.complemento);

        setIsLoading(false);
      })
      .catch(() => {
        setValue('logradouro', null);
        setValue('bairro', null);
        setValue('localidade', null);
        setValue('uf', null);
        setValue('pais', null);
        setValue('complemento', null);

        alert('não foi possível obter os dados do cep ' + cepFieldValue);
        setIsLoading(false);
      })
  }

  return (
    <Container>
      <ContainerBackgroundBanner>
        <ContainerBannerInfos>
          <TitleBanner>
            Você faz parte de uma iniciativa ou dá suporte à inovação social?
            Cadastre-se
          </TitleBanner>
        </ContainerBannerInfos>
      </ContainerBackgroundBanner>
      <SuportTitle>Ator de Suporte</SuportTitle>
      <Form ref={formRef} onSubmit={handleSubmit(addPost)} onReset={reset}>
        <ContainerIndiceForm>
          <IndiceForm>Introdução</IndiceForm>
          <DataContainerForm>
            <ContainerInputs>
              <InputText
                id="nome"
                label="Nome da organização"
                type="text"
                require={true}
                width="61%"

                registro={register("nome")}

              />

              <InputText
                id="sigla"
                label="Sigla"
                type="text"
                require={false}
                width="18%"
                registro={register("sigla")}
              />
              <YearSelectForm id="dataInicio" label={"Ano de início"} registro={register("dataInicio")} />

              <InputText
                id="cep"
                label="CEP"
                type="text"
                require={true}
                isCep={true}
                width="19%"
                placeholder="00000-000"
                mask="99999-999"
                maskChar="_"
                registro={register("cep", { onBlur: onCepFieldBlurChange })}
              />

              <InputText
                id="logradouro"
                label="Logradouro"
                type="text"
                require={true}
                width="48%"
                value={watch('logradouro')}
                registro={register("logradouro")}
              />

              <InputText
                id="numero"
                label="Número"
                type="text"
                require={false}
                width="10%"
                registro={register("numero")}
              />
              <InputText
                id="complemento"
                label="Complemento"
                type="text"
                require={false}
                width="20%"
                value={watch('complemento')}
                registro={register("complemento")}
              />
              <InputText
                id="bairro"
                label="Bairro"
                type="text"
                require={true}
                width="25%"
                value={watch('bairro')}
                registro={register("bairro")}
              />

              <InputText
                id="localidade"
                label="Cidade"
                type="text"
                require={true}
                width="24%"
                value={watch('localidade')}
                registro={register("localidade")}
              />

              <InputText
                id="uf"
                label="Estado"
                type="text"
                require={true}
                width="24%"
                value={watch('uf')}
                registro={register("uf")}
              />

              <InputText
                id="pais"
                label="País"
                type="text"
                require={true}
                width="24%"
                value={watch('pais')}
                registro={register("pais")}
              />

              <InputText
                id="representante"
                label="Nome do representante da organização"
                type="text"
                require={false}
                width="61%"
                registro={register("representante")}
              />

              <InputText
                id="email"
                label="E-mail da organização"
                type="email"
                require={true}
                width="38%"
                registro={register("email")}
              />

              <InputText
                id="telefone"
                label="Tel. da organização"
                type="text"
                require={true}
                isTelefone={true}
                totalNumber={10}
                placeholder="(00) 00000-0000"
                mask="(99) 99999-9999"
                maskChar="_"
                width="32%"
                registro={register("telefone")}
              />

              <InputText
                id="telComercial"
                label="Tel. comercial"
                type="text"
                require={false}
                isTelefone={true}
                totalNumber={10}
                placeholder="(00) 00000-0000"
                mask="(99) 99999-9999"
                maskChar="_"
                width="33%"
                registro={register("telComercial")}
              />

              <InputText
                id="celular"
                label="Celular"
                type="text"
                require={false}
                isTelefone={true}
                totalNumber={11}
                placeholder="(00) 00000-0000"
                mask="(99) 99999-9999"
                maskChar="_"
                width="33%"
                registro={register("celular")}
              />
              <InputText
                id="redeSocial1"
                label="Rede Social 1"
                type="text"
                require={false}
                width="32%"
                registro={register("redeSocial")}
              />
              <InputText
                id="redeSocial2"
                label="Rede Social 2"
                type="text"
                require={false}
                width="33%"
                registro={register("redeSocial2")}
              />
              <InputText
                id="redeSocial3"
                label="Rede Social 3"
                type="text"
                require={false}
                width="33%"
                registro={register("redeSocial3")}
              />

              <InputText id="site" label="Site" type="text" require={false} registro={register("site")} />

              <AccordionForm
                id="tipo-organizacao"
                label="Tipo de Organização"
                required={true}
                options={optionsTipoOrganizacao}
                onCheckboxChange={(checked, value) => handleCheckboxChange(checked, value, "tipoOrganizacao")}
                description="Selecione..."
                height={"36px"}
              />

              <AccordionForm
                id="origem-organizacao"
                label="Origem da Organização"
                required={true}
                options={optionsOrigemOrganizacao}
                onCheckboxChange={(checked, value) => handleCheckboxChange(checked, value, "origemOrganizacao")}
                description="Selecione..."
                height={"36px"}
              />

              <SelectForm
                id="abrangencia"
                label="Escala de abrangência"
                type="select"
                require={true}
                options={optionsAbrangencia}
                registro={register("abrangencia")}
              />
              <TitleForm>
                Indique as suas três principais formas de atuação no suporte à
                inovação social
              </TitleForm>
              <PerformanceContainer>
                <SelectForm
                  id="principalAtuacao"
                  label="1 - Mais importante"
                  type="select"
                  require={true}
                  options={optionsAtuacao}
                  width={"32%"}
                  registro={register("principalAtuacao")}
                  onSelectChange={(selectedValue) => onSelectChange("principalAtuacao", selectedValue, "atuacao")}
                  atuacao={selectedAtuacoes}
                />

                <SelectForm
                  id="secundariaAtuacao"
                  label="2 - Importância média"
                  type="select"
                  require={false}
                  options={optionsAtuacao}
                  width={"32%"}
                  registro={register("secundariaAtuacao")}
                  onSelectChange={(selectedValue) => onSelectChange("secundariaAtuacao", selectedValue, "atuacao")}
                  atuacao={selectedAtuacoes}
                />

                <SelectForm
                  id="terciariaAtuacao"
                  label="3 - Menos importante"
                  type="select"
                  require={false}
                  options={optionsAtuacao}
                  width={"32%"}
                  registro={register("terciariaAtuacao")}
                  onSelectChange={(selectedValue) => onSelectChange("terciariaAtuacao", selectedValue, "atuacao")}
                  atuacao={selectedAtuacoes}
                />

              </PerformanceContainer>
            </ContainerInputs>
          </DataContainerForm>
        </ContainerIndiceForm>

        <ContainerIndiceForm>
          <IndiceForm>Atividades e Público</IndiceForm>
          <DataContainerForm>
            <ContainerInputs>
              <TitleForm>Público alvo</TitleForm>
              <PerformanceContainer>
                <SelectForm
                  id="principalPublico"
                  label="1 - Mais importante"
                  type="select"
                  require={true}
                  options={optionsPublicoAlvo}
                  width={"32%"}
                  registro={register("principalPublico")}
                  onSelectChange={(selectedValue) => onSelectChange("principalPublico", selectedValue, "publico")}
                  atuacao={selectedPublico}
                />

                <SelectForm
                  id="secundarioPublico"
                  label="2 - Importância média"
                  type="select"
                  require={false}
                  options={optionsPublicoAlvo}
                  width={"32%"}
                  registro={register("secundarioPublico")}
                  onSelectChange={(selectedValue) => onSelectChange("secundarioPublico", selectedValue, "publico")}
                  atuacao={selectedPublico}
                />

                <SelectForm
                  id="terciarioPublico"
                  label="3 - Menos importante"
                  type="select"
                  require={false}
                  options={optionsPublicoAlvo}
                  width={"32%"}
                  registro={register("terciarioPublico")}
                  onSelectChange={(selectedValue) => onSelectChange("terciarioPublico", selectedValue, "publico")}
                  atuacao={selectedPublico}
                />
              </PerformanceContainer>
              <TextAreaForm
                id="atividades-desempenhada-suporte"
                label="Atividades desempenhadas no suporte à Inovação Social"
                height="200px"
                registro={register("atividadesPublicos")}
              />
            </ContainerInputs>
          </DataContainerForm>
        </ContainerIndiceForm>
        <ContainerIndiceForm>
          <IndiceForm>Iniciativas, Apoiadores e Parcerias</IndiceForm>
          <DataContainerForm>
            <ContainerInputs>
              <TextAreaForm
                id="iniciativa1"
                label="Indique organizações que promovem iniciativas de inovação social que você da suporte ou
                apoia diretamente (1/3)"
                height="200px"
                registro={register("iniciativa1")}
              />

              <TextAreaForm
                id="iniciativa2"
                label="Indique organizações que promovem iniciativas de inovação social que você da suporte ou
                apoia diretamente (2/3)"
                height="200px"
                registro={register("iniciativa2")}
              />

              <TextAreaForm
                id="iniciativa3"
                label="Indique organizações que promovem iniciativas de inovação social que você da suporte ou apoia diretamente (3/3)"
                height="200px"
                registro={register("iniciativa3")}
              />
              <TextAreaForm
                id="indicacaoParceiro"
                label="Indique seus principais parceiros nas ações de suporte às iniciativas de inovação social"
                height="200px"
                registro={register("indicacaoParceiro")}
              />

              <RadioForm
                expand={true}
                typeExpand={"check"}
                label="A Organização atua em alguma causa especifica?"
                labelInputText="Quais causas?"
                options={optionsCausa}
                nameRadio={"causa-especifica"}
                registroRadio={register("causaEspecifica")}
                description="Selecione..."
                onCheckboxChange={(checked, value) => handleCheckboxChange(checked, value, "causaEspecifica")}
              />

              <RadioForm
                id="inovacaoPropria"
                expand={true}
                typeExpand={"textArea"}
                label="Além das ações de suporte à outras organizações, a instituição tem ações de inovação social
                próprias?"
                labelInput="Quais essas ações?"
                nameRadio={"inovacaoPropria"}
                height="200px"
                registroRadio={register("inovacaoPropria")}
                idExpandInput="descricaoInovacaoPropria"
                registro={register("descricaoInovacaoPropria")}
              />

              <RadioForm
                id="indicacaoAtor"
                expand={true}
                typeExpand={"textArea"}
                label="Você poderia indicar outros atores de suporte de iniciativas de inovação social?"
                labelInput="Quais?"
                nameRadio={"indicacaoAtor"}
                height="200px"
                registroRadio={register("indicacaoAtor")}
                idExpandInput="descricaoIndicacao"
                registro={register("descricaoIndicacao")}
              />

              <RadioForm
                id="investimentoDireto"
                expand={true}
                typeExpand={"selectPlusInput"}
                label="A Organização faz investimento direto de recursos?"
                labelInput="Qual tipo de recursos?"
                labelInputDuo="Quanto por ano?"
                options={optionsRecursos}
                nameRadio={"investimentoDireto"}
                width={"55%"}
                registroRadio={register("investimentoDireto")}
                idExpandInput="descricaoRecurso"
                registroDuo={register("valorAnoInvestimento")}
                idExpandInputDuo="valorAnoInvestimento"
                onCheckboxChange={(checked, value) => handleCheckboxChange(checked, value, "descricaoRecurso")}
                description="Selecione..."
              />

              <RadioForm
                id="autorizoDivulgacao"
                expand={false}
                nameRadio={"autorizacao"}
                label="AUTORIZO A DIVULGAÇÃO DAS INFORMAÇÕES INSERIDAS NESTE FORMULÁRIO NA
          PLATAFORMA DO ECOSSISTEMA DE INOVAÇÃO SOCIAL DE PETRÓPOLIS"
                registroRadio={register("autorizoDivulgacao")}
              />
            </ContainerInputs>
          </DataContainerForm>
        </ContainerIndiceForm>
        <ContainerButtonForm>
          <ButtonReset type="reset" onClick={() => { reset(); window.scrollTo(0, 0); }}>Limpar</ButtonReset>
          <ButtonSubmit type="submit">Enviar</ButtonSubmit>
        </ContainerButtonForm>
        <ModalOverlay style={{ display: show ? 'flex' : 'none' }}>
          <StyledAlert show={show} variant="success">
            <Alert.Heading>Cadastro realizado com sucesso!</Alert.Heading>
            <p>
              Obrigado por cadastrar o seu ator de suporte!
            </p>
            <p>
              Entraremos em contato para mais informações.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => {
                setShow(false);
                reset();
                window.scrollTo(0, 0);
              }} variant="outline-success">
                Fechar
              </Button>
            </div>
          </StyledAlert>
        </ModalOverlay>
      </Form>

    </Container>

  );
}

export default SuportRegistration;



