import styled from "styled-components";

export const ContainerBackgroundBanner = styled.div`
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-color: #616161;
  background-blend-mode: multiply;
  width: 100%;
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
`;

export const ContainerBannerInfos = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 5%;
`;

export const TitleBanner = styled.h1`
  font-size: 34px;
`;
