import React, { useContext, useState } from "react";
import {
  ButtonReset,
  ButtonSubmit,
  Container,
  ContainerBackgroundBanner,
  ContainerBannerInfos,
  ContainerButtonForm,
  ContainerIndiceForm,
  ContainerInputs,
  DataContainerForm,
  Form,
  IndiceForm,
  InitiativeTitle,
  Inputs,
  ModalOverlay,
  PerformanceContainer,
  StyledAlert,
  TitleBanner,
} from "./styled";
import InputText from "../../components/InputText";
import RadioForm from "../../components/RadioForm";
import SelectForm from "../../components/SelectForm";
import TextAreaForm from "../../components/TextAreaForm";
import YearSelectForm from "../../components/YearSelectForm";
import { useForm } from "react-hook-form";
import { addInciativa } from "../../api/apiProjeto/apiProjeto";
import { Label } from "../../components/InputText/styled";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { getInfoByCep } from "../../api/apiViaCep";
import { getCoordenates, getGeocode } from "../../api/apiGeolocation";
import { LoadingContext } from "../../context/LoadingContext";

function InitiativeRegistration() {
  const optionsOrganização = ["Associação", "Coletivo Informal", "Cooperativa", "Empreendedor Pessoa Física", "Empresa com missão social e ambiental", "Fundação", "Movimento Social", "Organização sem fins lucrativos", "Plataforma ou aplicativo", "Programa de empresa RSC", "Programa Governamental", "Programa OSC", "Programa Universitário", "Rede"];
  const optionsCausa = ["Agricultura urbana", "Assistência social", "Ativismo, mobilização política e voluntariado", "Conservação e preservação ambiental", "Consumo consciente", "Cultura e arte", "Desenvolvimento comunitário", "Desenvolvimento urbano", "Direitos da pessoa idosa", "Direitos das crianças e adolescentes", "Direitos da mulher", "Direitos dos animais", "Direitos dos imigrantes e refugiados", "Direitos LGBT", "Educação", "Esporte e recreação", "Gestão de águas e saneamento", "Gestão de resíduos e reciclagem", "Inclusão de pessoas com deficiência", "População em situação de rua", "Questões Raciais e Étnicas", "Saúde", "Segurança", "Segurança Alimentar e Nutricional", "Trabalho e renda", "Transporte e mobilidade", "Uso de tecnologia para mudança social"];
  const optionsPublicoAlvo = ["Adultos", "Animais Domésticos", "Comunidades", "Crianças e Adolescentes", "Famílias", "Fundação", "Fauna", "Flora", "Governo", "Idosos", "Jovens", "Mulheres", "Pessoas com deficiência", "Pessoas racializadas", "Redes"];
  const { register, handleSubmit, reset, setValue, watch } = useForm();
  const [show, setShow] = useState(false);
  const [cepInfo, setCepInfo] = useState({});

  const { setIsLoading } = useContext(LoadingContext);

  const addPost = async (data) => {
    data.ativo = "mapeado";
    const cep = data.cep.replace(/\D/g, "");

    console.log(cepInfo);
    setIsLoading(true)
    try {
      const cepInfo = await getInfoByCep(cep);

      const response = await getCoordenates(cepInfo.data.logradouro, cepInfo.data.bairro, cepInfo.data.localidade);

      if (response && cep.length === 8) {
        console.log(response);
        const { lat, lng } = response;
        data.latitude = lat;
        data.longitude = lng;

        addInciativa(data);

        setShow(true);
      } else {
        alert('Erro ao cadastrar informações, confira antes de tentar novamente!');
        throw new Error("A resposta da API não contém informações de geometria.");
      }
    } catch (error) {
      alert("Erro: CEP inválido.");
      console.error(error);
      setShow(false);
    }
    setIsLoading(false)
  };

  // const onCepFieldBlurChange = (event) => {
  //   const cepFieldValue = event.target.value
  //   const maxCepFieldLength = 9

  //   const cepFieldValueLength = cepFieldValue?.length;
  //   if (cepFieldValueLength !== maxCepFieldLength)
  //     return;

  //   setIsLoading(true);

  //   getInfoByCep(cepFieldValue)
  //     .then((viaCepInfo) => {
  //       setCepInfo(viaCepInfo.data);

  //       setValue('logradouro', cepInfo.logradouro);
  //       setValue('bairro', cepInfo.bairro);
  //       setValue('localidade', cepInfo.localidade);
  //       setValue('uf', cepInfo.uf);
  //       setValue('pais', 'Brasil');
  //       setValue('complemento', cepInfo.complemento);

  //       setIsLoading(false);
  //     })
  //     .catch(() => {
  //       setValue('logradouro', null);
  //       setValue('bairro', null);
  //       setValue('localidade', null);
  //       setValue('uf', null);
  //       setValue('pais', null);
  //       setValue('complemento', null);

  //       alert('não foi possível obter os dados do cep ' + cepFieldValue);
  //       setIsLoading(false);
  //     })
  // }

  return (
    <Container>
      <ContainerBackgroundBanner>
        <ContainerBannerInfos>
          <TitleBanner>
            Você faz parte de uma iniciativa ou dá suporte à inovação social?
            Cadastre-se
          </TitleBanner>
        </ContainerBannerInfos>
      </ContainerBackgroundBanner>
      <InitiativeTitle>Iniciativa</InitiativeTitle>
      <Form onSubmit={handleSubmit(addPost)} onReset={reset}>
        <ContainerIndiceForm>
          <IndiceForm>Introdução</IndiceForm>
          <DataContainerForm>
            <ContainerInputs>
              <InputText
                id="nome"
                label="Nome da organização"
                type="text"
                require={true}
                width="61%"
                registro={register("nome")}
              />

              <InputText
                id="sigla"
                label="Sigla"
                type="text"
                require={false}
                width="18%"
                registro={register("sigla")}
              />
              <YearSelectForm id="dataInicio" label={"Ano de início"} registro={register("dataInicio")} />

              <InputText
                id="cep"
                label="CEP"
                type="text"
                require={true}
                isCep={true}
                width="19%"
                placeholder="00000-000"
                mask="99999-999"
                maskChar="_"
                registro={register("cep")}
              />
              <InputText
                id="logradouro"
                label="Logradouro"
                type="text"
                require={true}
                width="48%"
                // value={watch('logradouro')}
                registro={register("logradouro")}
              />

              <InputText
                id="numero"
                label="Número"
                type="text"
                require={false}
                width="10%"
                registro={register("numero")}
              />
              <InputText
                id="complemento"
                label="Complemento"
                type="text"
                require={false}
                width="20%"
                // value={watch('complemento')}
                registro={register("complemento")}
              />
              <InputText
                id="bairro"
                label="Bairro"
                type="text"
                require={true}
                width="25%"
                // value={watch('bairro')}
                registro={register("bairro")}
              />

              <InputText
                id="cidade"
                label="Cidade"
                type="text"
                require={true}
                width="24%"
                // value={watch('localidade')}
                registro={register("localidade")}
              />

              <InputText
                id="uf"
                label="Estado"
                type="text"
                require={true}
                width="24%"
                // value={watch('uf')}
                registro={register("uf")}
              />

              <InputText
                id="pais"
                label="País"
                type="text"
                require={true}
                width="24%"
                // value={watch('pais')}
                registro={register("pais")}
              />


              <InputText
                id="representante"
                label="Nome do representante da organização"
                type="text"
                require={false}
                width="61%"
                registro={register("representante")}
              />

              <InputText
                id="email"
                label="E-mail da organização"
                type="email"
                require={true}
                width="38%"
                registro={register("email")}
              />

              <InputText
                id="telefone"
                label="Tel. da organização"
                type="text"
                require={true}
                isTelefone={true}
                totalNumber={10}
                placeholder="(00) 00000-0000"
                mask="(99) 99999-9999"
                maskChar="_"
                width="32%"
                registro={register("telefone")}
              />

              <InputText
                id="telComercial"
                label="Tel. comercial"
                type="text"
                require={false}
                isTelefone={true}
                totalNumber={10}
                placeholder="(00) 00000-0000"
                mask="(99) 99999-9999"
                maskChar="_"
                width="33%"
                registro={register("telComercial")}
              />

              <InputText
                id="celular"
                label="Celular"
                type="text"
                require={false}
                isTelefone={true}
                totalNumber={11}
                placeholder="(00) 00000-0000"
                mask="(99) 99999-9999"
                maskChar="_"
                width="33%"
                registro={register("celular")}
              />
              <InputText
                id="redeSocial"
                label="Rede Social 1"
                type="text"
                require={false}
                width="32%"
                registro={register("redeSocial")}
              />
              <InputText
                id="redeSocial2"
                label="Rede Social 2"
                type="text"
                require={false}
                width="33%"
                registro={register("redeSocial2")}
              />
              <InputText
                id="redeSocial3"
                label="Rede Social 3"
                type="text"
                require={false}
                width="33%"
                registro={register("redeSocial3")}
              />

              <InputText id="site" label="Site" type="text" require={false} registro={register("site")} />

              <SelectForm
                id="tipo-organizacao"
                label="Tipo de Organização"
                type="select"
                require={true}
                options={optionsOrganização}
                registro={register("tipoOrganizacao")}
              />

              <TextAreaForm
                id="promotorIniciativa"
                label="Principais promotores da iniciativa"
                height="200px"
                registro={register("promotorIniciativa")}
              />
            </ContainerInputs>
          </DataContainerForm>
        </ContainerIndiceForm>

        <ContainerIndiceForm>
          <IndiceForm>Causa e Público</IndiceForm>
          <DataContainerForm>
            <ContainerInputs>
              <PerformanceContainer>
                <Label>Indique <b>até três</b> principais causas que a iniciativa atua</Label>
                <Inputs>
                  <SelectForm
                    id="causa1"
                    label="1 - Primeira causa"
                    type="select"
                    require={true}
                    options={optionsCausa}
                    width={"32%"}
                    registro={register("causa1")}
                  />

                  <SelectForm
                    id="causa2"
                    label="2 - Segunda causa"
                    type="select"
                    require={false}
                    options={optionsCausa}
                    width={"32%"}
                    registro={register("causa2")}
                  />

                  <SelectForm
                    id="causa3"
                    label="3 - Terceira causa"
                    type="select"
                    require={false}
                    options={optionsCausa}
                    width={"32%"}
                    registro={register("causa3")}
                  />
                </Inputs>
              </PerformanceContainer>
              <PerformanceContainer>
                <Label>Indique <b>até três</b> principais públicos-alvo da iniciativa. Ou seja, quem a iniciativa busca atingir?</Label>
                <Inputs>
                  <SelectForm
                    id="categoriaPublicoIniciativa1"
                    label="1 - Primeiro público-alvo"
                    type="select"
                    require={true}
                    options={optionsPublicoAlvo}
                    width={"32%"}
                    registro={register("categoriaPublicoIniciativa1")}

                  />

                  <SelectForm
                    id="categoriaPublicoIniciativa2"
                    label="2 - Segundo público-alvo"
                    type="select"
                    require={false}
                    options={optionsPublicoAlvo}
                    width={"32%"}
                    registro={register("categoriaPublicoIniciativa2")}
                  />

                  <SelectForm
                    id="categoriaPublicoIniciativa3"
                    label="3 - Terceiro público-alvo"
                    type="select"
                    require={false}
                    options={optionsPublicoAlvo}
                    width={"32%"}
                    registro={register("categoriaPublicoIniciativa3")}
                  />
                </Inputs>
              </PerformanceContainer>
            </ContainerInputs>
          </DataContainerForm>
        </ContainerIndiceForm>

        <ContainerIndiceForm>
          <IndiceForm>Incidência, problemas e soluções</IndiceForm>
          <DataContainerForm>
            <ContainerInputs>
              <TextAreaForm
                id="problemasPublicos"
                label="Descreva os problemas públicos que visam responder"
                height="200px"
                registro={register("problemasPublicos")}
              />

              <TextAreaForm
                id="solucaoProblemas"
                label="Respostas ou soluções colocadas em prática para responder ao problema público"
                height="200px"
                registro={register("solucaoProblemas")}
              />

              <TextAreaForm
                id="incidencia"
                placeholder="Incidência em ação pública, seja na comunidade, governo, nas políticas ou programas públicos."
                label="Possui incidência na esfera pública?"
                height="200px"
                registro={register("incidencia")}
              />
            </ContainerInputs>
          </DataContainerForm>
        </ContainerIndiceForm>

        <ContainerIndiceForm>
          <IndiceForm>Parceiros e financiadores</IndiceForm>
          <DataContainerForm>
            <ContainerInputs>
              <RadioForm
                id="apoioFinaceiro"
                expand={true}
                label="A iniciativa recebe apoio financeiro?"
                labelInput="Se sim, qual organização presta esse suporte?"
                typeExpand={"input"}
                nameRadio={"apoioFinaceiro"}
                registroRadio={register("apoioFinaceiro")}
                idExpandInput="suporteFinanceiro"
                registro={register("suporteFinanceiro")}
                registerType={"iniciativa"}
              />

              <RadioForm
                id="outroApoio"
                expand={true}
                typeExpand={"input"}
                nameRadio={"tipo-apoio"}
                label="A iniciativa recebe algum outro tipo de apoio?"
                subText="(Exemplos de apoio: formação, apoio técnico, certificação, articulação; faz parte de algum programa
            governamental, RSC, OSC ou universitário...)"
                labelInput="Se sim, qual organização presta esse suporte?"
                registroRadio={register("outroApoio")}
                idExpandInput="outroSuporte"
                registro={register("outroSuporte")}
                registerType={"iniciativa"}
              />
              <TextAreaForm
                id="indicacaoIniciativa"
                label="Indique outras iniciativas de inovação social que sejam suas parceiras"
                height="200px"
                registro={register("indicacaoIniciativa")}
              />

              <RadioForm
                id="autorizoDivulgacao"
                expand={false}
                nameRadio={"autorizacao"}
                label="AUTORIZO A DIVULGAÇÃO DAS INFORMAÇÕES INSERIDAS NESTE FORMULÁRIO NA
          PLATAFORMA DO ECOSSISTEMA DE INOVAÇÃO SOCIAL DE PETRÓPOLIS"
                registroRadio={register("autorizoDivulgacao")}
                registerType={"iniciativa"}

              />
            </ContainerInputs>
          </DataContainerForm>
        </ContainerIndiceForm>
        <ContainerButtonForm>
          <ButtonReset type="reset" onClick={() => { reset(); window.scrollTo(0, 0); }}>Limpar</ButtonReset>
          <ButtonSubmit type="submit">Enviar</ButtonSubmit>
        </ContainerButtonForm>
        <ModalOverlay style={{ display: show ? 'flex' : 'none' }}>
          <StyledAlert show={show} variant="success">
            <Alert.Heading>Cadastro realizado com sucesso!</Alert.Heading>
            <p>
              Obrigado por cadastrar a sua iniciativa!
            </p>
            <p>
              Entraremos em contato para mais informações.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => {
                setShow(false);
                reset();
                window.scrollTo(0, 0);
              }} variant="outline-success">
                Fechar
              </Button>
            </div>
          </StyledAlert>
        </ModalOverlay>
      </Form>

    </Container>

  );
}

export default InitiativeRegistration;
